

// //BCA HERE//
import BCAWeb from "./Events/BCA/webkriti.jpeg"
import BCACode from "./Events/BCA/codefest.jpeg"
import BCARobots from "./Events/BCA/robotics.jpeg"
// import BCARoadies from "./Events/BCA/roadies.jpeg"
import BCAAntak from "./Events/BCA/antakshari.jpeg"
import BCAShort from "./Events/BCA/shortFilm.png"
import BCADance from "./Events/BCA/dance.jpeg"

// //BBA HERE//
import BBACarnival from "./Events/BBA/carnival.jpeg"
import BBAQuiz from "./Events/BBA/BBA_quizeria.jpeg"
import BBACulturang from "./Events/BBA/cultarang.jpeg"
import BBAFilm from "./Events/BBA/filmwars.jpeg"
import BBAmarketing from "./Events/BBA/Marketing.jpeg"

////DELED HERE//
import DELCom from "./Events/DELED/ComDEL.jpeg"
import DELICT from "./Events/DELED/ICT.jpeg"
import DELLearning from "./Events/DELED/LEarningMAt.jpeg"
import DELMehandi from "./Events/DELED/MEhandi.jpeg"
import DELRangoli from "./Events/DELED/RangoliFacePai.jpeg"
import DELRecycle from "./Events/DELED/REcycle.jpeg"
import DELSolo from "./Events/DELED/SOLOSONG.jpeg"

////LAW HERE//
import melodian from "./Events/LAW/Melody.png"
import facePaint from "./Events/LAW/facePaint.jpg"
import hurdles from "./Events/LAW/Hurdles.jpg"
import picGuess from "./Events/LAW/PicGuess.png"
import rangoliLaw from "./Events/LAW/Rangoli.png"
import treasureLaw from "./Events/LAW/Tresure.jpg"
import tugwar from "./Events/LAW/TUGWAR.png"
import knowKnock from "./Events/LAW/knowledgeKnock.jpeg"
import minuteMar from "./Events/LAW/minuteMarvel.jpeg"

//////B.Tech CS
import CSSnake from "./Events/BTECH/CS/1.png"
import CSTreasure from "./Events/BTECH/CS/2.png"
import CSBalloon from "./Events/BTECH/CS/3.png"
import CSRoadies from "./Events/BTECH/CS/4.png"
import CSTekken from "./Events/BTECH/CS/5.png"
import CSIot from "./Events/BTECH/CS/6.png"
import CSWall from "./Events/BTECH/CS/7.png"
import CSHackathon from "./Events/BTECH/CS/8.png"


//////B.Tech EE
import EEARM from "./Events/BTECH/EE/ARM.PNG"
import EEBowl from "./Events/BTECH/EE/BOWLING.PNG"
import EEElect from "./Events/BTECH/EE/ELECT.PNG"
import EEMachine from "./Events/BTECH/EE/Machine.PNG"
import EEMatlab from "./Events/BTECH/EE/MATLAB.PNG"
import EEPlc from "./Events/BTECH/EE/PLC.PNG"
import EEShooting from "./Events/BTECH/EE/Shooting.PNG"
import EESpell from "./Events/BTECH/EE/Spell.PNG"
import EEThleg from "./Events/BTECH/EE/thLeg.PNG"
import EETouch from "./Events/BTECH/EE/Touch.PNG"
import EEUnlock from "./Events/BTECH/EE/UNLOCK.PNG"

/////BTECH CE
import BridgeDes from "./Events/BTECH/CE/Bridgw.png"
import TechQuiz from "./Events/BTECH/CE/TechQuiz.png"
import HouseCards from "./Events/BTECH/CE/HouseCard.png"
import MemeMake from "./Events/BTECH/CE/MemeMaking.png"
import Tambola from "./Events/BTECH/CE/Tambola.png"

////// BTECH ECE
import RoboRace from "./Events/BTECH/ECE/RoboRace.jpg"
import AlgoDes from "./Events/BTECH/ECE/AlgoDEs.jpg"
import ProDes from "./Events/BTECH/ECE/ProTeus.jpg"
import OpenMic from "./Events/BTECH/ECE/OpenMix.jpg"
import BGMI from "./Events/BTECH/ECE/BGMI.jpg"
import NFS from "./Events/BTECH/ECE/NFS.jpg"
import DartShoot from "./Events/BTECH/ECE/Dart.jpg"
import JunkYard from "./Events/BTECH/ECE/JunkYard.jpg"
import TechQuiz2 from "./Events/BTECH/ECE/TechQuiz2.jpg"

//////BTECH ME
import baloonBL from "./Events/BTECH/ME/balloonBlast.png"
import eyeCont from "./Events/BTECH/ME/eyeCont.png"
import freeFire from "./Events/BTECH/ME/freeFire.png"
import openMic from "./Events/BTECH/ME/openMic.png"
import photoGraphy from "./Events/BTECH/ME/photoGraphy.png"
import water from "./Events/BTECH/ME/water.png"

/////////MBA
import Adselfie from "./Events/MBA/Adselfie.png"
import clashClan from "./Events/MBA/ClashClan.png"
import jingle from "./Events/MBA/Jingle.png"
import lionDen from "./Events/MBA/lionDen.png"
// import mFest from "./Events/MBA/MFest.png"
import nukkadNatak from "./Events/MBA/NukkadNatak.jpg"
import start50 from "./Events/MBA/Start50.JPG"
import nextUni from "./Events/MBA/TheNextUni.png"
import turnCoat from "./Events/MBA/TurnCoart.png"

////SRIMSH
import salad from "./Events/SRIMSH/salad.png"
// import GroupDance from "./Events/SRIMSH/GDance.jpg"
import FashionShowS from "./Events/SRIMSH/fashionShows.png"
import SoloDance from "./Events/SRIMSH/Solodance.png"
import waste from "./Events/SRIMSH/Waste.jpg"

/////SIP SIP
import bestWaste from "./Events/SIP/bestWaste.jpg"
import fastFood from "./Events/SIP/fastFood.png"
import ModelRep from "./Events/SIP/ModelRep.jpg"
import pharmaQuiz from "./Events/SIP/PharmaQuiz.jpeg"
import rangoliPh from "./Events/SIP/Rangoli.jpg"
import sacRace from "./Events/SIP/SacRace.jpeg"
import soloSingSIP from "./Events/SIP/soloSing.jpg"
import speechSIP from "./Events/SIP/Speech.jpeg"
import posterPres from "./Events/SIP/PosterRes.jpg"


export const eventsData = [
  {
  //   //////////////////////////////////BCA BCA BCA BCA BCA BCA BCA BCA BCA BCA 
    id: 1,
    category:"SCE",
    image: BCAWeb,
    name: "WebKriti",
    description:
      "Calling all creative minds! Unleash your web design skills in the WebKriti contest! We're searching for a stunning, user-friendly website design that captures the essence of [project/company].  This is your chance to win Prize and see your work come to life. Submit your entries ",
    rules: [
      "Individuals who are enrolled in an Undergraduate Degree program are eligible to participate.",
      "Team participants must provide proof of their college affiliation.",
      "The project concept or idea must be a unique and original creation, free from any potential violation of existing intellectual property rights.",
      "Each student is limited to participation in a single group, as per the established guidelines.",
      "It is expected that the presentation will be delivered within a ten-minute time frame, in adherence to the established guidelines. Teams will also be given 5 minutes additional slot to present their working prototype in front of judges, if any.",
      "All the events will commence at the scheduled time; Punctuality will be appreciated.",
    ],
    prizes: ["Awesome Cash Prizes And Many More.."],
    team: "Individual",
  //   // fees: "₹100 per team",
    contactInfo: [
     "Mr. Adarsh Pandey : 8188885128",
      "Mr. Krishnendra Tiwari : 6387629423",
      "Mr. Badal Mishra : 8810755040",
    ],
    location: "SCE Seminar Hall",
    date: "04 July",
    note: [
      "Teams having a working model of their idea will be considered as a plus point by judging panel.",
      "If any kind of trolling, offending, or misbehaving seen by participants will not be tolerated.",
    ],
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",
  },

  //////CODEFEST
  {
    id: 2,
    category:"SCE",
    image: BCACode,
    name: "CodeFest",
    description:
      " A Codefest event is a competitive coding competition designed exclusively for college students enrolled in any course  program from any college. Participants solve coding challenges or develop software projects within a set  timeframe, typically ranging from a few hours to a couple of days. What is Codefest About?",
   
    prizes: ["EXICITING PRIZES AND REWARDS"],
    team: "Individual",
    fees: "Free",
    contactInfo: ["Mr. Farhan Sami : +91 6386028417",
      " Mr. Imran Khan : +91 7310351070(whatsapp)"],
    location: "SCE COmputer Lab",
    date: "4th July 1:00 pm - 2:00 pm",
    // note: [""],
    // link: "https://www.hackerrank.com/codefest751831",
  },

  /////SHORT FILMS
  {
    id: 3,
    category:"SCE",
    image: BCAShort,
    name: "Short Film Festival",
    description:
    "Lights, camera, action! Submit your short film to our competition and showcase your talent. We're looking for [genre or theme (optional) stories that will move us, make us laugh, challenge us.",
    rules: [
     " Video Duration : 3 - 5 min ",
     "Recording will be only accepted if Recorded by Phone ( DSLR not Allowed )",
    ],
    prizes: ["EXICITING PRIZES AND REWARDS"],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Mr. Yash Pandey : +91 8874377426",
      "Mr. Imran Khan : 7310351070(whatsapp)",
    ],
    location: "S.C.E Seminar Hall",
    date: "5th july 2024",
    // note: [""],
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",
  },


  ///////////
  {
    id: 4,
    category:"SCE",
    image: BCAAntak,
    name: "Antakshari",
    description:
    [
      "..",
  ],
    rules: [
      "There will be two rounds for Antaksari.",
      "First round is speed round.",
      "In the speed round the maximum number of songs that the participants sing continuously in 30 sec will be their record.",
      "Second round is lyrics round.",
      "The middle line in the lyrics around will be given by the jury and the main line will be found out by  the participants.",

    ],
    prizes: [
      "Exciting cash prizes",
    ],
    team: "individual",
    fees: "Free",
    contactInfo: [
      " : 9370438737",
      "Aditya Raj Chaudhary : +91 7457918908",
      "Aashish Pal : +91 8400102084",
    ],
    location: "SCE dept. RN : 6206",
    date: "4th July 3:00pm - 5:00pm",
    // note: "",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",
    // onSpot: "",
  },
  {
    id: 5,
    category:"SCE",
    image: BCADance,
    name: "Aaja Nachle",
    description:
      [""],
    rules: [
      "For solo dance 1 participant allow at a time song time limit 1-2 minutes.",
      "For duet participants allow only in pair timming for song 1-2 minutes.",
      "For duet dance participants minimum 4 and maximum 6 members group will be formed.",
      "No particular theme has to be followed. The participants are free to mould the dance form.",
      "The participants will be judged on their costumes, compatibility, visual representation and dance form.",
      "Decision of judges will be final and binding",
    ],
    prizes: ["Exciting prizes"],
    team: "Individual",
    fees: "Free",
    contactInfo: [
      "Mansi Yadav : 8604702410",
      "Khushi Kesarwani : 6392970396",
    ],
    location: "S.C.E Seminar Hall",
    date: "5th july 2024",
    // note: ["NA"],
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",

  },
  {
    id: 6,
    category:"SCE",
    image: BCARobots,
    name: "ROBO RACE",
    description:[
      "Robo Race is a groundbreaking motorsport series featuring fully autonomous, electric racing cars controlled by artificial intelligence.",
      "Established to push the boundaries of Al and autonomous driving technology, Robo Race showcases innovative advancements in a competitive environment.",
      "The series promotes sustainability with its electric vehicles and serves as a testing ground for cutting-edge Al, sensor technology, and machine learning.",
      "Teams from diverse backgrounds develop their own Al software to navigate and strategize on the track, competing in both time trials and head-to-head races.",
    ],
    rules: [
      "This is racing event, so fastest and balanced robot will win.",
      "No practice will be allowed on the arena.",
      "Two persons are allowed in a team and they can be from different institutes.",
      "College ID is must for the participants.",
      "The robot will be judged on the basis of (in priority):",
      "1) Time to complete the track.",
      "2) Number of checkpoints cleared.",
      "No team will get a second chance after completing the track with a poor score.",

    ],
    prizes: ["NA"],
    team: "Two persons in a team allowed. they can be from different institutes.",
    fees: ["NA"],
    contactInfo: [
      "Mayank Kushwaha : 6306351485",
      " Sangam Sahni : 9369309280",
     "Himanshu Chaurasiya : 7233095336",
    ],
   
    location: "S.I.E.T Ground floor, badminton court",
    date: "4th july 2024, 10:00 am - 12:00 noon",
    // note: ["NA"],
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",
  },




      //////////////////////////////////BBA BBA BBA BBA BBA 
  {
    id: 7,
    category:"SCE",
    image: BBAmarketing,
    name: "Marketing Knocktip",
    description: [
      "Marketing knocktip event is based your Future business innovative plans / other new innovative Strategies/ New services according to Market requirements and satisfy their needs and cover all the market segments and build a powerful image. ",
    ],
    rules: [
      "The Presentation must be 3-4 minutes based upon the product of your choice.",
      "Only Individual OR Duo teams allowed.",
      "Presentation to be Shared before hand on the Contact number mention ( +916393240836 ).",
      "Prizes for only Winners and Runner up.",
    ],
    prizes: ["Exciting Prizes"],
    team: ["Only Individuals or Duo teams allowed"],
    fees: "Free",
    contactInfo: [
      "Priya Mishra : 6393240836",
      "Samriddhi Srivastava : 9140938430",
    ],
    location: "S.C.E Seminar Hall",
    date: "4th july 2024, 10:00 am - 11:30 am",
    // note: ["NA"],
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",
  },
  {

    id: 8,
    category:"SCE",
    image: BBAFilm,
    name: "Film Wars",
    description: [
      "This Competition is based on short videos or Films of your videography and Editing skills and Because short videos immediately captivate viewers, the likelihood of people watching the full video is way higher than longer videos.",
    ],
    rules: [
      "Videos duration must be 3 -5 minutes only.",
      "Your content should be decent and inspiration.",
      "Prizes for only Winners and Runner up",
    ],
    prizes: [
      "Exciting Prizes",
    ],
    team: "Individuals or Duo Teams",
    fees: "Free",
    contactInfo: ["Deepak Kumar Ojha : 8081075269",
      "Priyanshi Srivastava : 7607198321",
    ],
    location: "S.C.E Seminar Hall",
    date: "4th july 2024, 12:00 pm - 02:00 pm",
    // note: ["NA"],
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",
  },
  {
    id: 9,
    category:"SCE",
    image: BBACarnival,
    name: "The Great Carnival",
    description: [
      "This amazing event is based on the Cultural representation of traditional foods, Drinks, Accessories, Sketches, hand made crafts through the stalls.. and explained about his speciality in different cultures.",
    ],
    rules: [
      "Participants come before 30 minutes to prepare own stalls",
      "Make your own stalls poster’s",
      "Prizes for only Winners and Runner up.",
    ],
    prizes: [
      "Exciting Prizes",
    ],
    team: "Individual, duo and squad",
    fees: "Free",
    contactInfo: [
      "Yash Shukla : 7318305817",
      "Khushi Capoor : 9721079226",
    ],
    location: "S.C.E Department Room no. 6008 ",
    date: "4th july 2024, 03:00 pm - 06:00pm",
    // note: ["NA"],
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",
  },
  {
    id: 10,
    category:"SCE",
    image: BBAQuiz,
    name: "Quizzeria",
    description: [
      "A quiz competition of Bollywood and different sports. It Can develop their analytical, creative, problem-solving, communication, and confidence-building skills. ",
    ],
    rules: [
      "Prizes for only Winners and Runner-up.",
      "Only Individual and duo team allowed.",
      "You have only 30 second to guess the answers.",
      "If you know the answer you can simply raise your hand and give the answers. ",
    ],
    prizes: [
      "Exciting Cash Prizes and Many More Perks.... ",
    ],
    team: "Only Individual and duo team allowed",
    fees: "Free",
    contactInfo: [
      "Karan Thakur : 9336861480",
      "Animesh Singh : 6388478463",
    ],
    location: "S.C.E Seminar Hall",
    date: "5th july 2024, 10:00 pm - 12:00 pm",
    note: [
      "",
    ],
    link: "https://forms.gle/SC6FyJiXhBm4MQts7",
  },
   {
     id: 11,
     category:"SCE",
     image: BBACulturang,
     name: "Cultarang",
     description: [
       "Diverse cultures represent through the dance and singing. And you can recreate your favourite bollywood characters and different cultures looks and show case your talent with the audience.",
     ],
     rules: [
       "Performance Time limitation maximum 3-4 minutes.",
       "You can recreate your favourite bollywood characters looks.",
       "Prizes for Winners and Runner-up only.",
       "You can also perform any Actor and Actresses mimicry but for only 30 sec.",
     ],
     prizes: [
      "Exciting Cash Prizes and Many More Perks....",
     ],
     team: "team will be individual.duo or squad but singing team might be individual or duo only",
     fees: "free",
     contactInfo: [
      "Rishika Sahu : 6307118012",
      "Kshama Shankar Tiwari : 7523945535",
     ],
     location: "S.C.E Seminar Hall",
     date: "5th july 2024, 03:00pm - 06:00 pm",
     link: "https://forms.gle/SC6FyJiXhBm4MQts7",

   },
   

   
   //////////////HERE TO ADD NEW ENTRIES

   //////BTECH START HERE

   ////BTECHCSE BTECHCSE BTECHCSE BTECHCSE

   { // START
    id: 12,
    category:"SIET",
    image: CSSnake,  //NO_CHANGE 
    name: "SNAKE AND LADDER", /// This is the name Of EVENT
    description: [
      "Imagine a giant game board laid out on the grass, squares stretching as big as hopscotch. That's the scene for our life-sized game of Snakes and Ladders! We'll take turns rolling a giant die and race across the board. Landing on a ladder sends you scrambling up to a higher square, while snakes mean sliding down their backs. First one to reach the finish square wins, but watch out – a lucky roll or a sneaky snake could send you soaring ahead or plummeting back!", // HERE TO ADD DESCRIPTION
    ],
    rules: [
      "the game is played between two or more players (Max. 4 players).",
      "All players place their tokens just outside the board and must roll '1' to begin moving on the board.",
      "Rolling a '6' does not grant an extra turn.",
      "To win, a player must reach exactly 100th square, if the roll higher then the needed, the palyer loses the turn.",
    ], // RUles if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Anubhav Pandey : 6392009845", //contact 
     "Prachi Shukla : 9151133998", // Contact
     "Shristi Srivastava : 9695963684", // Contact
    ],
    location: "SIET reception",
    date: "4th july 2024, 02:00pm - 03:20 pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 13,
    category:"SIET",
    image: CSTekken,  //NO_CHANGE 
    name: "TEKKEN", /// This is the name Of EVENT
    description: [
      "Tekken 7 is a 3D fighting game where you choose from a roster of veterans like King and Nina, or new challengers like Lucky Chloe, to throw down in the final chapter of the Mishima clan feud.", // HERE TO ADD DESCRIPTION
    ],
    rules: [
      "No rules, just fight and defeat the opponent in game like a King",
    ], // RUles if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Rishi Singhal : 8787230299", //contact 
     "Utkarsh Mehta : 7905871803", // Contact
    ],
    location: "CSE Seminar Hall",
    date: "5th july 2024, 03:40pm - 05:00 pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END



  { // START
    id: 14,
    category:"SIET",
    image: CSRoadies,  //NO_CHANGE 
    name: "ROADIES", /// This is the name Of EVENT
    description: [
      "Calling all adventurers! Roadies is a college challenge that tests your limits. Brace yourself for mental and physical tasks designed to push your teamwork, problem-solving, and endurance. From brainteasers to epic obstacle courses, you'll need both strength and strategy to conquer each round. So, gather your crew, get ready to sweat, and prove you have the grit to be the ultimate Roadie!", // HERE TO ADD DESCRIPTION
    ],
    rules: [
      "This game consists of 3 rounds (Challenges and Rounds are surprise events)",
      "At the begining of each round, a challenge is selected, it can vary widely from endurance task to puzzle-solving.",
      "Each round feature an immunity task. The winner(s) of this task are safe from elimination.",
      "Players are expected to play with sportmanship and respect towards each other.",
    ], // RUles if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual or group(2-4 members)",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Arpit Pradhan : 6204504813", //contact 
     "Adi : 7457918908", // Contact
    ],
    location: "SIET Ground",
    date: "5th july 2024, 02:00pm - 03:30 pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 15,
    category:"SIET",
    image: CSHackathon,  //NO_CHANGE 
    name: "HACKATHON", /// This is the name Of EVENT
    description: [
      "Calling all code warriors! Are you ready to push your limits? Join our College Hackathon, a coding marathon where you'll tackle 4 problem-solving challenges in just 1.5 hours. Assemble your brainstorm solutions, and code your way to victory. This fast-paced event will test your technical skills and ability to think on your feet. Do you have what it takes to crack the code and claim the win? ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
      "It consists of only 1 round",
      "Time given : 1.5 hrs",
      "No. of Questions: 4",
      "Difficulty level: Easy, Easy-Medium, Medium, Medium-Hard",
      "participants are not allowed to bring their own laptops for participation. ",
    ], // RUles if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Rishi Singhal : 8787230299", //contact 
     "Pragati Srivastava: 9335970923", // Contact
    ],
    location: "CSE Department Lab",
    date: "4th july 2024, 12:00pm - 01:50 pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END

  { // START
    id: 16,
    category:"SIET",
    image: CSIot,  //NO_CHANGE 
    name: "IOT EXHIBITION", /// This is the name Of EVENT
    description: [
      "Dive into the future at the IoT Exhibition & Project Competition! Witness innovative projects that connect our world through sensors and smarts. Get inspired by student creations tackling real-world problems, from smart homes to environmental solutions.  See groundbreaking ideas come to life and join the discussion about the future of IoT technology. The competition heats up as teams vie for top honors. Don't miss your chance to be a part of the future!  ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
      "Anyone can participate, individually or as a group",
      "Presentation of project is compulsory",
      "Time Given: 3-4 mins",
      "Having a working prototype of project is a plus point",
      "Juding Criteria: Prototype, Working Model, Applicability, Presentation, Innovativeness",
    ], // RUles if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual or group (max 4 member)",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Ayush Tripathi : 9792295456", //contact 
     "KM Ekata: 9140060572", // Contact
    ],
    location: "CSE Department",
    date: "4th july 2024, 03:30pm - 05:00 pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 17,
    category:"SIET",
    image: CSBalloon,  //NO_CHANGE 
    name: "BALLOON RELAY RACE", /// This is the name Of EVENT
    description: [
      "Get ready for the Balloon Relay Race! Partner up and choose your trusty balloon. Together, you'll navigate a thrilling obstacle course, dodging hurdles and testing your teamwork. Can you keep your balloon unfallen while conquering the course? The first team to cross the finish line with their balloon intact wins! Be prepared for giggles and friendly competition – this race is sure to be a blast!", // HERE TO ADD DESCRIPTION
    ],
    rules: [
      "This game will be played with balloons",
      "The game will be played in pairs and you can choose your partners as per your own convenience.",
      "You have a cross a path of hurdles to reach final point.",
      "If the balloon fall or burst, team will have to start again or disqualified.",
    ], // RUles if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "group (4-6 members)",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Ansab Husain : 9452889022", //contact 
     "Shruti Mishra: 7237800705", // Contact
    ],
    location: "SIET Ground",
    date: "4th july 2024, 10:00am - 11:50 am",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 18,
    category:"SIET",
    image: CSTreasure,  //NO_CHANGE 
    name: "CLASH OF CLANS (TREASURE HUNT)", /// This is the name Of EVENT
    description: [
      "Ahoy there! Calling all adventurers! Embark on a daring quest for riches in the Treasure Hunt! But treasure won't be handed freely.  First, sharpen your wits in two trials! Test your logic and reasoning in the first challenge. Then, face the Extempore Trial, where your thinking on your feet will be put to the test. Conquer both, and the final adventure awaits: the Treasure Hunt itself! Decipher riddles, follow cryptic clues, and unearth the long-lost bounty! Are you ready to claim your fortune? ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
      "This game will be of 3 rounds",
      "First round: Reasoning test",
      "Second round: Extempore",
      "Final round: Treasure Hunt",
      "You are not allowed to use your phones during any round.",
    ], // RUles if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "group (4-5 members)",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Nikhil Singh : 7755007035", //contact 
     "Anubhav Pandey: 6392009845", // Contact
    ],
    location: "MBA Department(Base) + Whole College",
    date: "5th july 2024, 10:00am - 12:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 19,
    category:"SIET",
    image: CSWall,  //NO_CHANGE 
    name: "Wall Art", /// This is the name Of EVENT
    description: [
      "Unleash your inner artist and transform your walls into a canvas! Forget framed prints - wall art here is the living, breathing expression of your creativity. Bold geometric shapes dance across the space, or perhaps a whimsical forest scene climbs your living room wall. The possibilities are endless, as limited only by your imagination and the colors at your fingertips. So grab your favorite paintbrush, and breathe life directly onto your walls! ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
      "You have to bring your own colors and paintbrushes",
    ], // RUles if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual or group (1-4 members)",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Pragati Srivastava : 9335970923", //contact 
     "Shruti Mishra: 7237800705", // Contact
    ],
    location: "CSE Department",
    date: "5th july 2024, 10:00am - 12:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END

  ///END OF BTECHCSE

  //////BTECHCE BTECHCE BTECHCE

  { // START
    id: 20,
    category:"SIET",
    image: BridgeDes,  //NO_CHANGE 
    name: "BRIDGE DESIGN", /// This is the name Of EVENT
    description: [
      "Bridge design is like crafting a balancing act. It's about creating a strong and stable structure to carry weight across a distance. Engineers consider factors like traffic flow, wind resistance, and even potential earthquakes. Different bridge types, like beam or arch designs, excel under varying conditions. The key is selecting the right materials and configuration to ensure the bridge bears the load safely for years to come. ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual or group (1-4 members)",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Viplav Singh : 9798543235", //contact 
     "Amit Kumar: 6306669291", // Contact
    ],
    location: "4th block Room No: 4106",
    date: "4th july 2024, 10:00am",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END
 

  { // START
    id: 21,
    category:"SIET",
    image: TechQuiz,  //NO_CHANGE 
    name: "TECHNICAL QUIZ", /// This is the name Of EVENT
    description: [
      "Ready to test your civil engineering knowledge? This quiz dives into various areas like structural analysis, surveying, and geotechnical engineering. You'll face questions on material properties, design principles, and real-world applications. Be prepared to analyze forces, calculate loads, and identify best practices for safe and efficient structures. Good luck!  ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Abhay Kushwaha : 9616868804", //contact 
     "Praveen Kumar : 8953893130", // Contact
    ],
    location: "4th block Room No: 4201",
    date: "4th july 2024, 01:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END

  { // START
    id: 22,
    category:"SIET",
    image: HouseCards,  //NO_CHANGE 
    name: "HOUSE OF CARDS", /// This is the name Of EVENT
    description: [
      "A house of cards is a precarious feat of balance. Standard playing cards are precariously stacked, leaning against each other to form a pyramid-like structure. The key is creating a strong foundation. Often, two cards are propped up like a tent, with a third card balancing across their peaks. Subsequent layers follow suit, with each card meticulously placed to distribute weight evenly. It's a delicate dance, and the slightest tremor can send the whole house tumbling down.   ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Swatantra Kushwaha : 8933987197", //contact 
     "Rohit Tripathi: 8707895542", // Contact
    ],
    location: "4th block Room No: 4003",
    date: "4th july 2024, 02:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END

  { // START
    id: 23,
    category:"SIET",
    image: MemeMake,  //NO_CHANGE 
    name: "MEME MAKING", /// This is the name Of EVENT
    description: [
      "Meme making is like digital alchemy, turning pop culture gold into laugh-out-loud commentary. You take a familiar image, add a witty caption, and presto! A meme is born, perfectly capturing a relatable struggle or a clever observation. The best memes are both funny and insightful, leaving you nodding and chuckling at the same time. So next time you're scrolling through social media, take a closer look at those funny images with text - you might just be witnessing a mini-masterpiece of internet culture.   ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Tilak Sonkar : 8188927398", //contact 
     "Navneet Shukla : 9793471856", // Contact
    ],
    location: "4th block Room No: 4105",
    date: "5th july 2024, 10:00am",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 24,
    category:"SIET",
    image: Tambola,  //NO_CHANGE 
    name: "TAMBOLA", /// This is the name Of EVENT
    description: [
      "Tambola, also known as Housie, is a fun Indian game similar to Bingo. Players mark off numbers on their tickets as a caller randomly announces them from 1 to 90. The first to complete a specific pattern of marked numbers, like a line or the entire card, wins the prize!   ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Sadhana : 8090982372", //contact 
     "Sachin Patel : 7525816515", // Contact
    ],
    location: "4th block Room No: 4105",
    date: "5th july 2024, 01:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END

  ///END OF BTECHCE

  ////BTECHECE BTECHECE BTECHECE

  { // START
    id: 25,
    category:"SIET",
    image: RoboRace,  //NO_CHANGE 
    name: "ROBO RACE", /// This is the name Of EVENT
    description: [
      "Calling all tech whizzes! Put your coding and engineering skills to the test in the Robo Race! Design your own robot, navigate it through a challenging track, and race against others. The fastest bot with the smoothest moves wins!  ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual or team (2 members)",
    fees: "free", // NO CHANGE
      contactInfo: [
        "Mayank Kushwaha : 6306351485",
        " Sangam Sahni : 9369309280",
       "Himanshu Chaurasiya : 7233095336",
    ],
    location: "Badminton Court",
    date: "4th july 2024, 10:00am - 12:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 26,
    category:"SIET",
    image: AlgoDes,  //NO_CHANGE 
    name: "ALGORITHM DESIGN", /// This is the name Of EVENT
    description: [
      "Algorithm design is the process of creating instructions to solve a specific problem. It's like a recipe for computers, breaking down the problem into steps that use data structures to organize information. Different techniques, like sorting or searching, are chosen based on the problem's nature to find the most efficient solution.   ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     " Abhishek Shukla: 8765314217",
     "Vijay Kumar : 9696030368", //contact 

    ],
    location: "Block 2-First Floor [DSP Lab-2110]",
    date: "4th july 2024, 10:00am - 12:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END



  { // START
    id: 27,
    category:"SIET",
    image: ProDes,  //NO_CHANGE 
    name: "PROTEUS DESIGN", /// This is the name Of EVENT
    description: [
      "Proteus Design Suite lets you create electronic circuits virtually. You build schematics by placing electronic components and wiring them together. Proteus simulates the circuit's behavior, helping you identify issues before building it physically. You can even design the circuit board layout (PCB) within Proteus, ready for manufacturing.    ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Aastha Shukla : 9044410701 ", //contact 
     "Vijay Kumar : 9696030368", // Contact
    ],
    location: "block 3 second floor Lab 6",
    date: "5th july 2024, 10:00am - 01:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 28,
    category:"SIET",
    image: TechQuiz2,  //NO_CHANGE 
    name: "TECHNICAL QUIZ", /// This is the name Of EVENT
    description: [
      "A technical quiz tests your knowledge on a specific technology area. It can cover anything from computer programming languages and software to hardware components and cybersecurity.  These quizzes help assess your understanding and can be a fun way to learn new things or prepare for certifications.   ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Sangam Sahni : 9369309280 ", //contact 
     "Himanshu Chaurasiya : 7233095336", // Contact
    ],
    location: "Block 2-First Floor Room No:-2101",
    date: "4th july 2024, 01:00pm - 04:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 29,
    category:"SIET",
    image: OpenMic,  //NO_CHANGE 
    name: "OPEN MIC", /// This is the name Of EVENT
    description: [
      "Calling all singers, poets, comedians, and talents alike! An open mic contest is your chance to showcase your skills on stage for a live audience and potentially win prizes. It's a fun and supportive atmosphere to perform original work or test new material.   ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual or group",
    fees: "free", // NO CHANGE
    contactInfo: [
    "Sangam Sahni : 9369309280 ", //contact 
     "Himanshu Chaurasiya : 7233095336", // Contact
    ],
    location: "Block 2-First Floor [ECE Seminar hall]",
    date: "4th july 2024, 01:00pm - 04:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 30,
    category:"SIET",
    image: BGMI,  //NO_CHANGE 
    name: "BGMI", /// This is the name Of EVENT
    description: [
      "Test your Battlegrounds Mobile India skills in a contest! Compete against others for a chance to win prize money. Earn points through kills and placement in the final circle. Solo or team options available. Check online for upcoming tournaments and registration details.  ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "team (4 members)",
    fees: "100/-", // NO CHANGE
    contactInfo: [
     " Aditya Pratap Singh: 9263217020", //contact 
     "Abhishek Shukla: 8765314217 ", // Contact
    ],
    location: "block 2 third floor Mechanical Computer lab",
    date: "5th july 2024, 10:00am - 01:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 31,
    category:"SIET",
    image: NFS,  //NO_CHANGE 
    name: "NFS", /// This is the name Of EVENT
    description: [
      "Calling all NFS enthusiasts! Get ready to rev your engines for the NFS gaming contest at our college techfest! Race your virtual cars to the finish line and showcase your driving skills. More details coming soon, so stay tuned!   ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     "Aditya Pratap Singh: 9263217020 ", //contact 
     "Abhishek Shukla: 8765314217 ", // Contact
    ],
    location: "Block 2-First Floor [DSP Lab-2110]",
    date: "5th july 2024, 02:00pm - 04:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END



  { // START
    id: 32,
    category:"SIET",
    image: DartShoot,  //NO_CHANGE 
    name: "DART SHOOTING", /// This is the name Of EVENT
    description: [
      "Calling all sharp shooters! Test your aim and precision at our Techfest Dart Shooting competition. Hit the bullseye and top the leaderboard to win exciting prizes. Don't miss out on this fun and challenging event!  ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     " Tanushree: 6387911415", //contact 
     " Aditi rai : 8574072258", // Contact
    ],
    location: "Block 3-First Floor [Room No:- 3104]",
    date: "4th july 2024, 01:00pm - 04:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END


  { // START
    id: 33,
    category:"SIET",
    image: JunkYard,  //NO_CHANGE 
    name: "JUNKYARD", /// This is the name Of EVENT
    description: [
      "Calling all tinkerers! Junkyard Wars is your chance to unleash your engineering genius.  Work with your team to transform a pile of scrap into an amazing creation!  Can you build the strongest bridge, the fastest car, or something even more imaginative?  Let's see your creativity and problem-solving skills take center stage! ", // HERE TO ADD DESCRIPTION
    ],
    rules: [
       "Contact student coordinators for rules and further details",
       "Fill the form to participate",
    ], // Rules if needed
    prizes: [
     "Exciting Cash Prizes and Many More Perks....", // change if needed
    ],
    team: "individual",
    fees: "free", // NO CHANGE
    contactInfo: [
     " Aditi rai : 8574072258", //contact 
     "Alisha Siddiqui : 7704891287 ", // Contact
    ],
    location: "Block 2-First Floor [PCB Lab-2108]",
    date: "5th july 2024, 02:00pm - 04:00pm",
    link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
  }, ///END

 /////End of BTECHECE

 //BTECHEE BTECHEE BTECHEE

 { // START
  id: 34,
  category:"SIET",
  image: EEPlc,  //NO_CHANGE 
  name: "PLC & SCADA", /// This is the name Of EVENT
  description: [
    "The PLC Programming Championship tests your PLC knowledge and programming skills. You'll solve challenges that start with basic logic and progress to complex programs. Each round gets tougher, and the winner in the final round, who programs the most efficiently, will be crowned the PLC Guru ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "This multi-round competition will progressively assess your PLC knowledge and programming prowess.",
     "In first round you may be asked to make logic of some Gates or may be quiz related to PLC.",
     "Similarly in each round task will be assigned and successfully complete the tasks within each round to advance to the next level.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Utkarsha : 9161198645 ", //contact 
   "Rashid Khan : 8808532597 ", // Contact
  ],
  location: "Drive Lab",
  date: "4th july 2024, 09:30am - 11:30am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ // START
  id: 35,
  category:"SIET",
  image: EETouch,  //NO_CHANGE 
  name: "TOUCH ME NOT", /// This is the name Of EVENT
  description: [
    "The wire buzz game is a fun test of your hand-eye coordination. You guide a metal loop along a winding wire path without letting it touch. If it does, a buzzer sound! It's a simple but challenging game that's perfect for parties, events, or even a solo competition to see how steady your hand is. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "It's a tricky game that tests how steady you can hold a thin metal loop.",
     "In case you accidentally touch the wire and hear  the buzzer more than 3 times, game over for you",
     "So, keep those hands nice and steady to avoid getting kicked out, focus on being smooth to win.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "PRIYASH SRIVASTAVA : 7002258068 ", //contact 
   "SAFA RAFIQ : safa.rafiq02@gmail.com ", // Contact
  ],
  location: "EE Gallery",
  date: "4th july 2024, 10:00am - 12:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 36,
  category:"SIET",
  image: EESpell,  //NO_CHANGE 
  name: "SPELL BEE", /// This is the name Of EVENT
  description: [
    "Our spelling bee isn't your typical competition! It challenges your engineering vocabulary, with words getting tougher as you advance. Listen closely as the pronouncer gives each word just once. Spell each letter clearly, and remember, sportsmanship is a must – only one champion can emerge! ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "It is a game that test the vocabulary and spelling proficiency of participants. But tricky part is that you must know the technical terms related to engineering",
     "It is divided into rounds with the difficulty of words typically increasing as the competition progresses",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Bhargavi Raj : rajbhargavi702@gmail.com", //contact 
   "Adarsh Kumar Gaur : 7002258068 ", // Contact
  ],
  location: "EE Seminar Hall",
  date: "4th july 2024, 10:00am - 12:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 37,
  category:"SIET",
  image: EEMatlab,  //NO_CHANGE 
  name: "MATLAB", /// This is the name Of EVENT
  description: [
    "Are you ready to test your MATLAB mettle? This challenge is designed to put your data manipulation and analysis skills to the ultimate test.", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Ready to unleash the power of MATLAB? Join us for the MATLAB Programming Challenge, a thrilling competition designed to crown the ultimate MATLAB Maestro.",
     "This multi-round competition will progressively test your MATLAB knowledge and programming skills.",
     "Each round will present a unique challenge, from basic coding exercises to complex data analysis problems. Difficulty will increase as you advance.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Aman Singh Patel : 6394768295", //contact 
   "BHASKAR SRIVASTAVA : 7985859752", // Contact
  ],
  location: "Computer Lab",
  date: "4th july 2024, 01:30pm - 03:30pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 38,
  category:"SIET",
  image: EEARM,  //NO_CHANGE 
  name: "ARM WRESTLING", /// This is the name Of EVENT
  description: [
    "Arm wrestling is a classic test of strength. Facing your opponent, you lock hands together, fingers intertwined, like an upside-down handshake on a flat surface. Both feet must stay grounded for stability. On the referee's signal, the battle begins! The first person to pin their opponent's hand flat on the table wins. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "You and your opponent gotta face each other, elbows on the table, and hands locked together. Imagine a handshake with your arms flat on the table.",
     "At least one foot from each competitor needs to be in contact with the floor throughout the match.",
     "The referee will usually say Ready, Set, Go! to start the match.",
     "Whoever slams their opponent's hand down on the pad first wins! That's like their surrender.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "ADARSH KUMAR GAUR : 7002258068", //contact 
   "SHIVAM YADAV : 7905729216", // Contact
  ],
  location: "EE Classroom 2001",
  date: "4th july 2024, 03:30pm - 04:30pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 39,
  category:"SIET",
  image: EEMachine,  //NO_CHANGE 
  name: "MACHINE HUNT ", /// This is the name Of EVENT
  description: [
    "Machine Hunt is a game that tests your knowledge and problem-solving skills. Players draw cards describing malfunctions in electrical machines. You'll need to diagnose the issue, identify the affected machine, and explain your solution within a time limit. Points are awarded for accuracy and speed, with the highest scorer crowned the Machine Hunter. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Think you're a master of motors, a guru of generators? This game will put your electrical engineering knowledge to the ultimate test!",
     "Each player (or team) will blindly draw a card from the challenge pool. These cards will describe a malfunction or specific behaviour of an electrical machine or system.",
     "Carefully read and understand the challenge on your card. It might describe symptoms, faulty components, or desired functionalities.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Kanishk : 7307838478", //contact 
   "Ashish kanaujiya : 7905794181", // Contact
  ],
  location: "Machine Lab",
  date: "5th july 2024, 09:30am - 11:30am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 40,
  category:"SIET",
  image: EEShooting,  //NO_CHANGE 
  name: "SHOOTING ", /// This is the name Of EVENT
  description: [
    "It'll test your focus and precision by aiming for the highest accuracy possible. Participants will earn points for each shot that hits the target, with three chances to showcase their skills. The player with the most accumulated points after all shots are fired will be declared the winner! ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Shooting game challenge your focus and precision. The goal is typically to hit a target with highest accuracy possible.",
     "Participants will shoot at the target to earn points.",
     "You will get three chance to shoot and points of each will be awarded based on its accuracy.",
     "After all shots are fired, points of each shot will be added and the player with the highest total score wins.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "BHASKAR SRIVASTAVA : 7985859752", //contact 
   "BHARGAVI RAJ : rajbhargavi02@gmail.com", // Contact
  ],
  location: "Near lift, back side of EE Classroom 2001-2003",
  date: "5th july 2024, 10:00am - 12:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 41,
  category:"SIET",
  image: EEThleg,  //NO_CHANGE 
  name: "THREE-LEGGED RACE", /// This is the name Of EVENT
  description: [
    "The three-legged race, participants were divided into pairs, had their inner legs tied together, and then raced towards the finish line upon the starting signal, with the first team to cross winning the competition. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Three-legged race is a timed events where first  pair to cross the finish line wins.",
     "Participants will be divided into pairs.",
     "Inner legs (right leg of the left partner and left leg of the right partner) will be tied together securely but comfortably with the rope.",
     "The first pair to cross the finish line with both people together wins!",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Utkarsha : 9161198645", //contact 
   "ALKA YADAV : alkay3520@gmail.com", // Contact
  ],
  location: "Badminton Court",
  date: "5th july 2024, 01:30pm - 03:30pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 42,
  category:"SIET",
  image: EEElect,  //NO_CHANGE 
  name: "ELECT-TECH PICTIONARY", /// This is the name Of EVENT
  description: [
    "ELECT-Pictionary is a fast-paced drawing game where teams work together to guess electrotechnology terms based on silent drawings and hand gestures. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "It’s a group game so participants will be split into teams. And group will have 2 or 5 players.",
     "Group will elect a person called 'artist' . The artist will blindly pick a slip of paper from the bowl or hat. This slip will contain the electrotechnology term they need to get their team to guess.",
     "The Artist will then use their best artistic skills to draw clues on a whiteboard or large piece of paper, without using any  words, letters, or numbers",
     "The will be set for 30 sec for each round. ",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group(2-5 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Gaurav Bind : 8421425796", //contact 
   "Shivam Yadav : 7905729216", // Contact
  ],
  location: "EE Seminar Hall",
  date: "5th july 2024, 01:30pm - 03:30pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 43,
  category: "BTECH",
  image: EEBowl,  //NO_CHANGE 
  name: "BOWLING BALL", /// This is the name Of EVENT
  description: [
    "Knock down pins, rack up points! Roll a heavy ball down the lane, aiming for a strike (all pins down in one roll!). Each turn gets you one shot, and stepping over the line means no points. Highest score after all frames wins, with ties going extra rounds. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "It is a game in which a heavy ball is rolled down a long, narrow lane toward a group of objects known as pins, The aim is to knock down more pins than an opponent in a roll.",
     "You will get a chance to ball once and Points are awarded based on the number of pins knocked down in a frame",
     "Bowlers must release the ball from behind a designated line called the foul line. Stepping over this line during delivery renders the roll invalid.",
     "The ball must roll down the lane, not be thrown. ",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Priyash Srivastava : 9140794332", //contact 
   "Rashid Khan : 8808532597", // Contact
  ],
  location: "EE Gallery",
  date: "5th july 2024, 03:30pm - 04:30pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ // START
  id: 44,
  category: "BTECH",
  image: EEUnlock,  //NO_CHANGE 
  name: "UNLOCK YOUR PARTNER", /// This is the name Of EVENT
  description: [
    "Unlock Your Partner, communication is crucial! Teams of two player will race against the clock. One player, blindfolded, will search for hidden keys scattered around a designated area. Their partner, acting as the locked one, can only offer verbal guidance. The fastest team to successfully unlock their partner with the correct key wins ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Participants are divided into pairs. One player from each pair will be designated as the locked player and will be positioned in a specific area.",
     "Keys will be hidden within several pots scattered around the designated area.",
     "The other player, blindfolded, will be tasked with finding the correct key to unlock their partner.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Aman Singh Patel : 6394768295", //contact 
   "Ashish kanaujiya : 7905794181", // Contact
  ],
  location: "Near Lift",
  date: "5th july 2024, 03:30pm - 04:30pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END



//////////////////////////////////////ME BRANCH
////ME start from here
{ // START
  id: 45,
  category: "SIET",
  image: water,  //NO_CHANGE 
  name: "WATER ROCKETRY", /// This is the name Of EVENT
  description: [
    "Water Rocketry is a mechanical event that envolves the fabrication of a rocket whose fuel/ thrust for flight is obtanined by the use of water that is expelled at a large air pressure. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Each team must have max. 3 members and min. 2.",
     "Every stage is knock-out stage.",
     "Inappropriate behavior could lead to disqualification. Event coordinators have all the rights to take final decision for any matter during the event.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group (2-3 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Sravan Kumar : 8081927212", //contact 
   "Guddoo : 6306025905", // Contact
  ],
  location: "In front of Mechanical workshop",
  date: "4th july 2024, 09:15am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 46,
  category: "SIET",
  image: freeFire,  //NO_CHANGE 
  name: "FREE FIRE", /// This is the name Of EVENT
  description: [
    "Battle royale your way to victory in Free Fire contests! Test your skills against other players in solo, duo, or squad matches. Climb the leaderboard and win prizes like in-game items, diamonds, or even cash. Gear up, drop in, and be the last one standing! ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Each team must have min. 2 members and max.  4 members.",
     "Players should bring the own laptops because systems will not be provided by faculty.",
     "Inappropriate behavior could lead to disqualification. Event coordinators have all the rights to take final decision for any matter during the event.",
     "Use of any character skills, pet skills or gun skin is prohibited.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group (2-4)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Sayed Arbaz : 962888955", //contact 
   "Umesh Kushwaha : 7054569782", // Contact
  ],
  location: "Mechanical Computer Lab",
  date: "4th july 2024, 11:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END



{ // START
  id: 47,
  category: "SIET",
  image: openMic,  //NO_CHANGE 
  name: "OPEN MIC", /// This is the name Of EVENT
  description: [
    "The Open Mic competition is a cultural event, promote the talent of students like singing, poetry and stand up comedy. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact event coordinators for more details",
     "Fill the registration form for participation."
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Harsh Srivastava: 7985005153", //contact 
   "Aditya Pratap Singh: 9263217020", // Contact
  ],
  location: "Mechanical Seminar Hall",
  date: "5th july 2024, 10:30am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ // START
  id: 48,
  category: "SIET",
  image: eyeCont,  //NO_CHANGE 
  name: "EYE CONTACT", /// This is the name Of EVENT
  description: [
    "An eye contact contest, also known as a staring contest, is a test of willpower. Two participants lock eyes, and the first person to blink, flinch, or look away loses. It's a battle to overcome discomfort and maintain focus, with victory determined by pure eye power.  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact event coordinators for more details",
     "Fill the registration form for participation."
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Akhilesh Gupta : 7905553488", //contact 
   "Nishu Singh : 8866058919", // Contact
  ],
  location: "Applied Science and Humanities Department",
  date: "5th july 2024, 11:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 49,
  category: "SIET",
  image: baloonBL,  //NO_CHANGE 
  name: "BALLOON BLAST", /// This is the name Of EVENT
  description: [
    "Balloon Blast! Test your popping power in this exciting contest. Race to burst the most balloons or be the last one standing with an un-popped balloon. Fun for all ages! ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact event coordinators for more details",
     "Fill the registration form for participation."
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Pryanjal Mishra : 9026743283", //contact 
   "Ankit Kumar : 7398490705", // Contact
  ],
  location: "Applied Science and Humanities Department",
  date: "5th july 2024, 12:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 50,
  category: "SIET",
  image: photoGraphy,  //NO_CHANGE 
  name: "PHOTOGRAPHY CONTEST", /// This is the name Of EVENT
  description: [
    "Capture the essence of our city! This contest celebrates the beauty of Prayagraj's streetscapes and natural spaces. Submit your photos showcasing unique architecture, bustling markets, or serene parks. We're looking for entries that tell a story about our local environment.  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Each participants only submit one picture.",
     "Theme: Local streetscape, Nature",
     "Understand the theme or topic of the contest. Ensure your photograph align with it.",
     "Graphical editing is not allowed, only color grading is allowed.",
     "It must be the original work, stock photographs are prohibited.",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Kashif Rizvi : 9336151034", //contact 
   "Aditya Maurya : 8318914407", // Contact
  ],
  location: "2nd Block RN: 2304",
  date: "4th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

//////END of ME


//////MBA MBA MBA

{ // START
  id: 51,
  category:"SIM",
  image: nextUni,  //NO_CHANGE 
  name: "THE NEXT UNICORN", /// This is the name Of EVENT
  description: [
    "Form a team and business of either product or service and divide your team members according to five round each members goal is to get max amount of funds. Note all rounds will be conducting simultaneouly except the final round.  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Round 1 : Finance Pitch  to angle invester to get highest amount of funds",
     "Round 2: HR job Interview & highest salary Negotiation",
     "Round 3: Marketing promotion on instagram to get max follower count in 2 hours.",
     "Round 4: Sales ( sell your product or service to customers or persue them to give you max fake currencies in 1 hour and return back to start point within 10 mints , customers are all members present in mba department",
     "Round 5 : IT Presentation( it member has to keep your teams funds record from start to end and present it in front of entreprenure to double their team  funds)",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group(upto 5 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Ishani Shukla : 9455009089", //contact 
   "Himanshu Tiwari : 7081558111", // Contact
  ],
  location: "SIET Seminar Hall + MBA Dept.",
  date: "4th july 2024, 09:30am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ // START
  id: 52,
  category:"SIM",
  image: jingle,  //NO_CHANGE 
  name: "JINGLE AD", /// This is the name Of EVENT
  description: [
    "Calling all creative minds! Unleash your inner jingle writer! Our MBA program is hosting a contest to craft the catchiest jingle that captures the essence of getting your MBA. Win bragging rights and amazing prizes! Enter now!   ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Round 1: screening create a logo for shambhunath with a tagline and give your entries with registeration ",
     "Round 2: Prepare a non technical jingle ad and perfom it on stage by using any props of your choice ( jingle can be made of shayari, poem,rap,song etc) jingle shoud be creative and should have meaningful message ",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group(3-5)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Suraj Kesarwani : 9984716243", //contact 
   "Yogesh Mishra : 7071885854", // Contact
  ],
  location: "SIET Seminar Hall",
  date: "4th july 2024, 02:30pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 53,
  category:"SIM",
  image: turnCoat,  //NO_CHANGE 
  name: "TURN COAT", /// This is the name Of EVENT
  description: [
    " A participant is given any random topic and instantly has to speak 'For or Against' the topic in a duration of 5 minutes whenever the judge commands 'switch' , the speaker will have to toggle between For or Against Multiple times therby contradicting the previous statement  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact event coordinators for more details and rules",
     "Fill registration from to participate. ",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Shivi Shukla : 8795620770", //contact 
   "Ayushi Mishra : 9918232361", // Contact
  ],
  location: "MBA Dept. Room No: 3114",
  date: "4th july 2024, 01:30pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ // START
  id: 54,
  category:"SIM",
  image: start50,  //NO_CHANGE 
  name: "START WITH Rs 50", /// This is the name Of EVENT
  description: [
    " Each team will have to star with rupees 50 and sell a product to double triple the amount participants will have 4 hours to earn the most  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact event coordinators for more details and rules",
     "Fill registration from to participate. ",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group (4 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Ratikant Singh : 993566642", //contact 
   "Akash Singh : 8737895739", // Contact
  ],
  location: "MBA Dept. Room No: 3114",
  date: "4th july 2024, 11:30am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 55,
  category:"SIM",
  image: lionDen,  //NO_CHANGE 
  name: "LIONS DEN 2.0", /// This is the name Of EVENT
  description: [
    " Bring an innovation in product or service and pitch your idea in front of 7 judges ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact event coordinators for more details and rules",
     "Fill registration from to participate. ",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group (5 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Naman Kesarwani : 639146551", //contact 
   "Sonal Tripathi : 9455397247", // Contact
  ],
  location: "SIET Seminar Hall",
  date: "5th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 56,
  category:"SIM",
  image: clashClan,  //NO_CHANGE 
  name: "CLASH OF CLANS", /// This is the name Of EVENT
  description: [
    "Yohohoho Pirates!, This epic contest tests your mind, voice, and agility. Round 1:  Reasoning throws down a gauntlet with challenging puzzles to flex your strategic muscles. Round 2:  Extempore unleashes your inner orator - be prepared to think on your feet and captivate the crowd!  The final round takes you on a college-wide treasure hunt. Decipher cryptic clues, navigate hidden corners, and claim the ultimate prize! Gear up, Pirates - victory awaits! ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Round 1: Reasoning test",
     "Round 2: Extempore ",
     "Round 3: Treasure Hunt (Clues in whole campus to get letters of a word which you have to arrange in the last who arranges the word first wins)",
     "Ecah round will gives you some points or it can be a knockout round based on no. of participations.",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group (3-5 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Nikhil Singh : 7755007035", //contact 
   "Anubhav Pandey : 6392009845", // Contact
  ],
  location: "MBA Dept. + Whole College",
  date: "5th july 2024, 09:30am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 57,
  category:"SIM",
  image: nukkadNatak,  //NO_CHANGE 
  name: "NUKKAD NATAK", /// This is the name Of EVENT
  description: [
    "Social topic will be given and on the spot within 15 mints teams have to prepare a nukkad natak and present it in front of judges ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Conatct event coordinators for more details and rules",
     "Fill registration forms for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group (5 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "KArunesh Pandey : 9838177781", //contact 
   "", // Contact
  ],
  location: "MBA Dept.",
  date: "5th july 2024, 01:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 58,
  category:"SIM",
  image: Adselfie,  //NO_CHANGE 
  name: "AD SELFIE", /// This is the name Of EVENT
  description: [
    "Unleash your inner marketing whiz! The AD Selfie Contest is calling MBA brainiacs. Think outside the frame! Capture a creative selfie that doubles as a killer ad.  Showcase your persuasive chops with a catchy tagline. Prove you've got the business acumen to win! ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Round 1: Selfie (participants need to click a selfie with one of assigned products and give a persuasive tagline)",
     "Round 2: Video (all qualifying team needs shoot on the spot and submit a 2 mint. Video for branding the product)",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group (4 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Ritish Jaiswal : 8874088747", //contact 
   "", // Contact
  ],
  location: "MBA Room No: 3114",
  date: "5th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

///SRIMSH SRIMSH SRIMSH ////

{ // START
  id: 59,
  category:"SRIMSH",
  image: SoloDance,  //NO_CHANGE 
  name: "SOLO AND GROUP DANCE", /// This is the name Of EVENT
  description: [
    "Dance is a form of creative expression through physical movement that includes rhythmic movements that accompany music. A solo dance is a dance done by an individual dancing alone. Group dance refers to simultaneous choreographed dances performed in groups of three or more people. In other words, the dancers do the same moves at the same. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Time limit is 4-6 minutes ",
     "The participants are requested to bring two copy of dance music to avoid technical disturbance, out of which, they are also requested to submit one copy of dance music to the coordinator of the event.",
     "No extra time will be given for installing music. It should be done prior to the event",
     "The participants will be judged on their costumes, visual presentation and dance form. Their is no particular theme.",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group (8 members)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Kumkum : 7991929448", //contact 
   "Sagar : 6391141105", // Contact
  ],
  location: "SRIMSH Auditorium",
  date: "4th july 2024, 11:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ // START
  id: 60,
  category:"SRIMSH",
  image: waste,  //NO_CHANGE 
  name: "BEST OUT OF WASTE", /// This is the name Of EVENT
  description: [
    "Simply Making the best out of waste means creating something useful and innovative from items that would otherwise be thrown away. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Waste material Should be brought by the participants. ",
     "Time limit is 3;00 hours. ",
     "Waste material could be anything like tetra packs, bottles, newspapers, old utensils, jute material or any second hand items that otherwise would be thrown away. ",
     "The material would be rejected if not found to be a waste product or second hand item.",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Himanshu : 8081709453", //contact 
   "", // Contact
  ],
  location: "SRIMSH Auditorium",
  date: "5th july 2024, 12:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 61,
  category:"SRIMSH",
  image: salad,  //NO_CHANGE 
  name: "VEGETABLE CARVING", /// This is the name Of EVENT
  description: [
    "Vegetable carving is the art of carving vegetables to form beautiful objects, such as flowers or birds. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "The participants should bring all the necessary materials, including vegetables for the competition.  ",
     "Borrowing of materials is restricted.The participants should come well prepared. ",
     "Total time is 60 minutes (In addition to this 10 minutes will be given for cleaning and winding up.)  ",
     "All participants should be able to justify the appropriateness of the title. No scrapingor carving vegetables is allowed before the start of the event. ",
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Prashant : 8739900962", //contact 
   "Anshika : 7460080659", // Contact
  ],
  location: "SRIMSH Nutrition Lab",
  date: "5th july 2024, 02:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

// //////End of SRIMSH


////DELED DELED DELED

{ // START
  id: 62,
  category:"SCE",
  image: DELLearning,  //NO_CHANGE 
  name: "TEACHING LEARNING MATERIAL", /// This is the name Of EVENT
  description: [
    "Dive into the world of teaching and learning materials! This event explores how educators can craft engaging tools to boost student understanding. Expect workshops on designing effective resources, discussions on the latest trends, and a showcase of innovative teaching materials.  Join us and ignite a passion for creative learning!  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
  "Mr.Ankit Singh : 9026862502", //contact 
  ],
  location: "SCE Library",
  date: "4th july 2024, 10:00am - 12:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 63,
  category:"SCE",
  image: DELICT,  //NO_CHANGE 
  name: "ICT IN INCLUSIVE EDUCATION", /// This is the name Of EVENT
  description: [
    "Bridging the gap in education! This event dives into how Information and Communication Technologies (ICT) can empower educators to create inclusive learning environments. Expect interactive workshops on assistive technologies and digital learning tools, alongside expert presentations and panel discussions.  Join us to explore best practices, overcome challenges, and unlock the full potential of ICT for inclusive education.  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Mr.Aakib : 9369819206", //contact 
  ],
  location: "SCE Seminar Hall",
  date: "5th july 2024, 12:00pm - 02:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 64,
  category:"SCE",
  image: DELRecycle,  //NO_CHANGE 
  name: "REUSE, RECYCLE, RECREATE", /// This is the name Of EVENT
  description: [
    "Unleash your creativity in the Reuse, Recycle, Recreate Contest! Transform everyday discards into something extraordinary.  Compete with your upcycled creations -  furniture, fashion, art, anything goes! Show us how you breathe new life into old materials. Prizes await the most innovative and inspiring entries!  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
"Anshika Pal : 9161517103",
  ],
  location: "SCE Room No. 6008",
  date: "5th july 2024, 02:00pm - 04:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 65,
  category:"SCE",
  image: DELRangoli,  //NO_CHANGE 
  name: "RANGOLI AND FACE PAINTING", /// This is the name Of EVENT
  description: [
    "Unleash your creativity! Join our Rangoli & Face Painting Contest! Showcase your artistic flair with colorful rangolis and stunning face designs.  All ages are welcome to compete - individuals or teams!  Prizes awarded for the most vibrant rangolis and imaginative face paintings.  Let's celebrate art and color!  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Mr.Virendra Singh Maurya : 9569463533",
  ],
  location: "SCE Ground Floor",
  date: "4th july 2024, 01:00pm - 04:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 66,
  category:"SCE",
  image: DELMehandi,  //NO_CHANGE 
  name: "MEHANDI", /// This is the name Of EVENT
  description: [
    "Unleash your inner henna artist! Join our Mehndi competition and showcase your intricate designs. Whether you're a seasoned mehndi maestro or a creative beginner, there's a chance to win.  The competition is open to all - bring your talent and let your beautiful henna designs bloom!  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Sonam : 9369352616",
  ],
  location: "D. El. Ed Classroom",
  date: "4th july 2024, 04:00pm - 6:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 67,
  category:"SCE",
  image: DELSolo,  //NO_CHANGE 
  name: "SOLO AND DUET SONG", /// This is the name Of EVENT
  description: [
    "Unleash your voice! Join our Solo & Duet Singing Competition! Showcase your talent as a soloist or harmonize with a partner. Choose your favorite song and impress the judges with your vocal skills, stage presence, and musical chemistry (for duets). Prizes await the winning soloists and duet pairs. Don't miss this chance to shine! ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
"Diksha Singh : 8299769379",
"Vandita Pandey : 9682098657 "
  ],
  location: "SCE Seminar Hall",
  date: "5th july 2024, 04:00pm - 6:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


///////End of DELED

/////// /SIP SIP SIP/////

{ //START
  id: 68,
  category: "SIP",
  image: pharmaQuiz,  //NO_CHANGE 
  name: "PHARMA QUIZ", /// This is the name Of EVENT
  description: [
    "Test your drug knowledge! Join the Pharma Quiz Contest. Answer questions on medications, science, and industry trends. Compete solo or in teams for prizes and bragging rights. Open to students and professionals. Let's learn and have fun!  ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group(4 member)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Ashirwad Mishra : 9793948347", //contact 
   "Chitra Sahu : 8840204463", // Contact
  ],
  location: "SIP Seminar Hall",
  date: "4th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ //START
  id: 69,
  category: "SIP",
  image: ModelRep,  //NO_CHANGE 
  name: "MODEL PRESENTATION", /// This is the name Of EVENT
  description: [
    "A model presentation can showcase a product, concept, or even a complex system. It typically uses slides and visuals to engage the audience and explain the model's key features and benefits. The goal is to inform, educate, and persuade the audience about the value of the model.   ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group(2 member)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Kalpana Sanyal : 8467862245", //contact 
   "Mohini Yadav : 9569385560", // Contact
  ],
  location: "SIP Analysis Lab",
  date: "4th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ //START
  id: 70,
  category: "SIP",
  image: rangoliPh,  //NO_CHANGE 
  name: "PHARMA RANGOLI", /// This is the name Of EVENT
  description: [
    "Showcase your creativity! Join our Rangoli contest. Create a beautiful Rangoli design and compete for exciting prizes. Open to all skill levels. Let your colors shine!   ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group(4 member)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Garima Tripathi : 8176862494", //contact 
   "Amita Panday : 9555978003", // Contact
  ],
  location: "SIP 1st Floor",
  date: "4th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


// { //START
//   id: 71,
//   category: "SIP",
//   image: BBACulturang,  //NO_CHANGE 
//   name: "SPOTTING", /// This is the name Of EVENT
//   description: [
//     "A spotting contest is a fun challenge where people see who can find the most (or rarest) of something! It could be birds in a park, hidden objects in a picture, or even typos in a book. Grab your friends, sharpen your eyes, and see who's the spotting champion!  ", // HERE TO ADD DESCRIPTION
//   ],
//   rules: [
//      "Contact student event coordinators for more details and rules. ",
//      "Fill the registration form for participation.",
     
     
//   ], // Rules if needed
//   prizes: [
//    "Exciting Cash Prizes and Many More Perks....", // change if needed
//   ],
//   team: "individual",
//   fees: "free", // NO CHANGE
//   contactInfo: [
//    "Nikhil Gupta : 8922011404", //contact 
//    "Himanshu Tripathi : 9696058416", // Contact
//   ],
//   location: "SIP HAP Lab",
//   date: "5th july 2024, 10:00am",
//   link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
// }, ///END


{ //START
  id: 72,
  category: "SIP",
  image: posterPres,  //NO_CHANGE 
  name: "POSTER PRESENTATION", /// This is the name Of EVENT
  description: [
    "A poster presentation condenses your research or topic onto a visual display.  It uses clear headings, graphics, and concise text to engage viewers. You stand by your poster to answer questions and spark discussion during a conference or event.    ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group(2 member)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Aryan Kumar : 6206045718", //contact 
   "Arti Singh : 9695102506", // Contact
  ],
  location: "SIP B block corridor",
  date: "4th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ //START
  id: 73,
  category: "SIP",
  image: bestWaste,  //NO_CHANGE 
  name: "BEST OF WASTE", /// This is the name Of EVENT
  description: [
    "Unleash your creativity at the Best Out of Waste Contest! Transform everyday discards into amazing creations.  It's a fun way to reuse, recycle, and impress the judges with your eco-friendly ingenuity!    ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "group(3 member)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Kalpana Sanyal : 8467862245", //contact 
   "Mohini Yadav : 9569385560", // Contact
  ],
  location: "SIP Cognosy Lab",
  date: "4th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ //START
  id: 74,
  category: "SIP",
  image: fastFood,  //NO_CHANGE 
  name: "INSTA COOK", /// This is the name Of EVENT
  description: [
    "Cook without fire and heat is a difficult challenge! Would you dare to accept this challenge to be called The Best Chef.    ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group(2 member)",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Sumit Mishra : 6387991194", //contact 
   "Piyush Dubey : 8863067759", // Contact
  ],
  location: "SIP Meeting room",
  date: "4th july 2024, 01:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ //START
  id: 75,
  category: "SIP",
  image: speechSIP,  //NO_CHANGE 
  name: "EXTEMPORE", /// This is the name Of EVENT
  description: [
    "An extempore contest tests your wit and speaking skills on the spot. Participants get a short prep time on a surprise topic before delivering a brief speech. It's a mental sprint to organize thoughts and impress judges with content and confidence.   ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Praveen Tiwari : 9170474742", //contact 
   "Rajnish Varma : 8737918097", // Contact
  ],
  location: "SIP Seminar Hall",
  date: "5th july 2024, 01:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ //START
  id: 76,
  category: "SIP",
  image: sacRace,  //NO_CHANGE 
  name: "SACK RACE", /// This is the name Of EVENT
  description: [
    "A hilarious race where you hop, not run! Players climb into burlap sacks and jump their way to the finish line. First one across wins!    ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Nikhil Kesarwani : 7054637573", //contact 
   "Rajeev Gupta : 7525038003", // Contact
  ],
  location: "VBPS Ground",
  date: "5th july 2024, 10:00am",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ //START
  id: 77,
  category: "SIP",
  image: soloSingSIP,  //NO_CHANGE 
  name: "DANCING AND SINGING", /// This is the name Of EVENT
  description: [
    "Belt out your best tunes and bust a move! Join our singing and dancing contest, a night of showcasing vocal and rhythmic talents. Solo or duet, impress the judges with your stage presence and win amazing prizes!   ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
    "Time given for each performace is 4 min",
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
     
     
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
   "Mayank Mishra : 7071473646", //contact 
   "Simran Kesharwani : 9026426067", // Contact
  ],
  location: "SIP Seminar Hall",
  date: "4th july 2024, 01:00pm",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

////End of SIP


/////////////////////////////LAW LAW LAW LAW ID AFTER 77
{ // START
  id: 78,
  category:"SIL",
  image: melodian,  //NO_CHANGE 
  name: "Melodian Chair", /// This is the name Of EVENT
  description: [
    "Musical chairs is a classic party game where participants walk or dance around a circle of chairs while music plays. When the music stops, everyone must quickly find a chair to sit in. However, there are always fewer chairs than players, so one person is left without a seat and is eliminated from the game. A chair is then removed, and the process continues until only one person and one chair remain. The last person sitting is declared the winner.", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "Seminar Hall of SIL DEPT",
  date: "5th july 2024, 04:00pm onwards",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 79,
  category:"SIL",
  image: facePaint,  //NO_CHANGE 
  name: "Face Paint", /// This is the name Of EVENT
  description: [
    "Face painting is the art of applying cosmetic paint to a person's face, usually for entertainment or decorative purposes. It often involves using special paints and brushes to create designs, patterns, or even elaborate scenes on the face,commonly seen at festivals, parties, and theatrical performances.", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "Seminar Hall of SIL DEPT",
  date: "5th july 2024, 09:00am onwards",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 80,
  category:"SIL",
  image: hurdles,  //NO_CHANGE 
  name: "Challenge Circuit", /// This is the name Of EVENT
  description: [
    "An event, where all the participants have to clear series of barriers or hurdles, while on their way to win the competition", // HERE TO ADD DESCRIPTION
  ],
  rules: [
    "Only For Teachers",
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: " SIET Ground",
  date: "5th july 2024, 03:00pm onwards",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ // START
  id: 81,
  category:"SIL",
  image: picGuess,  //NO_CHANGE 
  name: "Tehqeeq-e-Tasveer", /// This is the name Of EVENT
  description: [
    "This is an event which begins with varied and interesting pictures of various aspects, leading to guess and answer which will lead every mind to get active. ", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "Seminar Hall of SIL DEPT",
  date: "5th july 2024, 09:00am onwards",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END



{ // START
  id: 82,
  category:"SIL",
  image: rangoliLaw,  //NO_CHANGE 
  name: "Row Of Colours", /// This is the name Of EVENT
  description: [
    "This event of Rangoli is to look on necessity of colors in the culture and tradition, in the heart of India. Rangoli is a traditional Indian art form involving the creation of intricate designs on the ground, usually at the entrance of homes, during festivals and special occasions.", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "SIL dept.",
  date: "5th july 2024, 09:00am onwards",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 83,
  category:"SIL",
  image: treasureLaw,  //NO_CHANGE 
  name: "Enigma Expedition", /// This is the name Of EVENT
  description: [
    "The participants are required to gather all the required clues and reach to the end to collect all, clues to final objects.", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "SIL dept.",
  date: "5th july 2024, 03:00pm onwards",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 84,
  category:"SIL",
  image: tugwar,  //NO_CHANGE 
  name: "Tug-Of-War", /// This is the name Of EVENT
  description: [
    "This is a competitive team sport where two teams, attempt to drag the other team across a central line or marker. The game tests strength, teamwork, and strategy, as each team tries to out-pull the other", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "SIET Ground",
  date: "5th july 2024, 09:00am onwards",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END


{ // START
  id: 85,
  category:"SIL",
  image: knowKnock,  //NO_CHANGE 
  name: "Knowledge Knockout", /// This is the name Of EVENT
  description: [
    "The Knowledge Knockout quiz competition is another highlight of the fest, emphasizing teamwork and comprehensive knowledge across various subjects", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "Seminar Hall of SIL DEPT.",
  date: "Date & Time: July 4th, 2024; 09:00AM - 12:00PM",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END
{ // START
  id: 86,
  category:"SIL",
  image: minuteMar,  //NO_CHANGE 
  name: "Minute Marvel", /// This is the name Of EVENT
  description: [
    "This Academic Event, named *Minute Marvel* is designed to test participants' ability to speak fluently and coherently on a given topic within a strict time limit. The rules for the competition are clear and rigorous.", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Contact student event coordinators for more details and rules. ",
     "Fill the registration form for participation.",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "Seminar Hall of SIL DEPT.",
  date: "July 4th, 2024; 01:00 - 04:00 PM",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

{ // START
  id: 87,
  category:"SRIMSH",
  image: FashionShowS,  //NO_CHANGE 
  name: "Fashion Show", /// This is the name Of EVENT
  description: [
    "It's colourful, lively, fun, exciting & would provide a dramatic & entertaining alternative related to any famous bollywood personality", // HERE TO ADD DESCRIPTION
  ],
  rules: [
     "Vulgarity is strongly prohibited. ",
     "Any form of obscenity will lead to debarring the team from the contest. ",
     "Use of cigarettes, alcohol and any unfair means is strongly prohibited. ",
     "Participants will be judged on costumes, theme, walking stance and attitude",
  ], // Rules if needed
  prizes: [
   "Exciting Cash Prizes and Many More Perks....", // change if needed
  ],
  team: "individual or group",
  fees: "free", // NO CHANGE
  contactInfo: [
// "Diksha Singh : 8299769379",
// "Vandita Pandey : 9682098657 "
  ],
  location: "SRIMSH Seminar Hall",
  date: "5th july 2024, 10:00am onwards",
  link: "https://forms.gle/SC6FyJiXhBm4MQts7", // NO change
}, ///END

];
