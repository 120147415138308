import React, { useRef } from "react";
import classes from "./Leaderboard.module.css";
//BCA
import imranP from "../assets/Events/CoOrdinators/imranP.JPG";
import addityap from "../assets/Events/CoOrdinators/aditya.jpeg";
//BBA
import rishikaBBA from "../assets/Events/CoOrdinators/rishikaBBA.jpeg";
//DELED
import ankitp from "../assets/Events/CoOrdinators/AnkitSingh.jpg";
//BTECH
import riship from "../assets/Events/CoOrdinators/Rishi.jpeg";
import sangamp from "../assets/Events/CoOrdinators/sangarmECE.jpeg";
//CSE EXTRA
import shrutiP from "../assets/Events/CoOrdinators/CsShruti.jpeg";
import ansabP from "../assets/Events/CoOrdinators/CsAnsab.jpg";
//BTECH EXTRA
import raunitEE from "../assets/Events/CoOrdinators/raunitEE.jpeg";
import ajayME from "../assets/Events/CoOrdinators/AjayME.jpg";
import viplavCE from "../assets/Events/CoOrdinators/ViplavCE.jpeg";
import pragatiCS from "../assets/Events/CoOrdinators/pragatiCS.jpeg";

//MBA
import utkarshMBA from "../assets/Events/CoOrdinators/UtkarshMBA.jpeg";
import ishaniMBA from "../assets/Events/CoOrdinators/MbaIshani.jpeg";
//LAw
import harshLAW from "../assets/Events/CoOrdinators/harshLaw.jpeg";
import saurabhLAW from "../assets/Events/CoOrdinators/SaurabhLAW.jpeg";
// SRIMSH
import kumSRI from "../assets/Events/CoOrdinators/kumkumSRI.jpg";
import vinaySRI from "../assets/Events/CoOrdinators/vinaySRI.jpg";
//SIP
import mayankSIP from "../assets/Events/CoOrdinators/mayankSIP.jpeg";
import akanshaSIP from "../assets/Events/CoOrdinators/akanshaSIP.jpeg";

const Leaderboard = () => {
  const sce = useRef();
  const btech = useRef();
  const sil = useRef();
  const sip = useRef();
  const mba = useRef();
  const usrimsh = useRef();

  const scrolledHeader = (elmRef) => {
    window.scrollTo({
      top: elmRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className={classes.leaderboard_section}>
        <h1 className={classes.heading}>Event Co-ordinators</h1>

        <div className={classes.buttonDivMain}>
        <button onClick={()=> scrolledHeader(sce)} className={classes.buttonNew} role="button">S.C.E</button>
<button onClick={()=> scrolledHeader(btech)} className={classes.buttonNew} role="button">S.I.E.T</button>
<button onClick={()=> scrolledHeader(mba)} className={classes.buttonNew} role="button">S.I.M</button>
<button  onClick={()=> scrolledHeader(sil)} className={classes.buttonNew} role="button">S.I.L</button>
<button onClick={()=> scrolledHeader(sip)}  className={classes.buttonNew} role="button">S.I.P</button>
<button onClick={()=> scrolledHeader(usrimsh)}  className={classes.buttonNew} role="button">SRIMSH</button>
        </div>

        {/* ///BUTTON */}

        {/* ////////////////////// */}
        {/* //////SCE DEPARTMENT// */}
        <div ref={sce} className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}> S.C.E Dept. (U.S.I.) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}


          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}

            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={imranP} />
              </header>
              <div className={classes.card__body}>
                {/* /// TAG NAME */}
                <div className={classes.card__category}>
                  <a href="#">Developer</a>
                </div>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Imran Khan</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator S.C.E )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  imranpgda@gmail.com
                </div>
                <p className={classes.card__description}>
                  Developer Of U.S.I Eureka 2024 Website.
                </p>
              </div>
            </article>

        { /*///////// */ }

{/* ////ONE CARD /// */}
<article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={addityap} />
              </header>
              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Aditya Raj</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">(Head Co-ordinator BCA )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  aditch14.rahul@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 7457918908
                </p>
              </div>
            </article>
            {/* ///////// */}
       

          
          </div>
        </div>

        {/* /////////////////////////////// */}

        {/* ////////////////////// */}
        {/* ///// BBA DELED DEPARTMENT// */}
        <div className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}> S.C.E Dept. (U.S.I.) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}
          <div className={classes.divForCards}>
          

            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={rishikaBBA} />
              </header>
              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Rishika Sahu</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">(Head Co-ordinator BBA )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  rishikasahu774@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 6307118012
                </p>
              </div>
            </article>

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={ankitp} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Ankit Singh</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-ordinator D.El.Ed )</a>
                </h3>
                <div className={classes.card__subtitle}>as162839@gmail.com</div>
                <p className={classes.card__description}>
                  Contact : 9026862502
                </p>
              </div>
            </article>
            {/* ///////// */}
          </div>
        </div>

        {/* /////////////////////////////// */}

        {/* ////////////////////// */}
        {/* ///// BTECH DEPARTMENT MAIN// */}
        <div ref={btech} className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}>B.Tech Dept. (U.S.I.) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}
          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={riship} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Rishi Singhal</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator U.S.I )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  rishisinghal882@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 8787230299
                </p>
              </div>
            </article>
            {/* ///////// */}

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={shrutiP} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Shruti Mishra</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator B.Tech CSE  )</a>
                </h3>
                <div className={classes.card__subtitle}>
                Mishraa.shrutii@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 9369301935
                </p>
              </div>
            </article>
            {/* ///////// */}
          </div>
        </div>

        {/* ///////EXTRA CSE BTECH */}
        <div ref={btech} className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}>B.Tech Dept. (U.S.I.) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}
          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={ansabP} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Ansab Hussain </a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator B.Tech CSE )</a>
                </h3>
                <div className={classes.card__subtitle}>
                ansabhusain@gmail.com 
                </div>
                <p className={classes.card__description}>
                  Contact : 9452889022
                </p>
              </div>
            </article>
            {/* ///////// */}

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={sangamp} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Sangam Sahni</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-ordinator B.Tech ECE )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  Sangamsahni18@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 9369309280
                </p>
              </div>
            </article>
            {/* ///////// */}
          </div>
        </div>


        

        {/* //////BTECH EXTRA */}

        <div className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}>B.Tech Dept. (U.S.I) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}
          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={ajayME} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Ajay Pandey</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator B.Tech (M.E) )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  ajaykumarpandeyofficial1@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 9956857781
                </p>
              </div>
            </article>
            {/* ///////// */}

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={raunitEE} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Raunit Kumar</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator B.Tech (E.E) )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  raunitg07@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 7355505469
                </p>
              </div>
            </article>
            {/* ///////// */}
          </div>
        </div>

        {/* /////////////////////////////// */}
        {/* //////BTECH EXTRA */}

        <div className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}>B.Tech Dept. (U.S.I) </h2>
          </div>
          
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}

          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={viplavCE} />
              </header>
              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Viplav Singh</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator B.Tech (C.E) )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  Contact : 9798543235
                </div>
                {/* <p className={classes.card__description}>
                  Contact : 8707895542
                </p> */}
              </div>
            </article>
            {/* ///////// */}

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={pragatiCS} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Pragati Shrivastava</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator B.Tech (C.S) )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  pragatisrivastava735@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 9335970923
                </p>
              </div>
            </article>
            {/* ///////// */}
          </div>
        </div>

        {/* /////////////////////////////// */}

        {/* ////////////////////// */}
        {/* /////MBA  DEPARTMENT// */}
        <div ref={mba} className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}>M.B.A Dept. (U.S.I.) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}
          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={ishaniMBA} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Ishani Shukla</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator MBA )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  ishanishukla1@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 9455009089
                </p>
              </div>
            </article>
            {/* ///////// */}

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={utkarshMBA} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Utkarsh Vishwakarma</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator MBA )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  vishwakarma.utkarsh01@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 7388572066
                </p>
              </div>
            </article>
            {/* ///////// */}
          </div>
        </div>

        {/* /////////////////////////////// */}

        {/* ////////////////////// */}
        {/* //////SIL DEPARTMENT// */}
        <div ref={sil} className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}>S.I.L Dept. (U.S.I.) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}
          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={saurabhLAW} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Saurabh Baghel</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator S.I.L )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  Saurabh.baghel2002@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 7830790834
                </p>
              </div>
            </article>
            {/* ///////// */}

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={harshLAW} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Harsh Shrivastava</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator S.I.L )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  harshsrivas26@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 9555335645
                </p>
              </div>
            </article>
            {/* ///////// */}

            {/* ///////// */}
          </div>
        </div>

        {/* /////////////////////////////// */}

        {/* ////////////////////// */}
        {/* /////SIP  DEPARTMENT// */}
        <div ref={sip} className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}>S.I.P Dept. (U.S.I.) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}
          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={mayankSIP} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Mayank Mishra</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator S.I.P )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  mmishra4687567@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 7071473646
                </p>
              </div>
            </article>
            {/* ///////// */}

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={akanshaSIP} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Akanksha Singh</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator S.I.P )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  2003akankshasingh@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 7318312125
                </p>
              </div>
            </article>
            {/* ///////// */}
          </div>
        </div>

        {/* /////////////////////////////// */}

        {/* ////////////////////// */}
        {/* /////USRIMSH  DEPARTMENT// */}
        <div ref={usrimsh} className={classes.CardDiv}>
          <div className={classes.CardDiv2}>
            <h2 className={classes.Dept}>SRIMSH. Dept. (U.S.I.) </h2>
          </div>
          {/* ///MAIN DIV TO ADD CARDS MULTIPLE  */}
          <div className={classes.divForCards}>
            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={vinaySRI} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Vinay Tripathi</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator SRIMSH )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  vt3895440@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 8528327548
                </p>
              </div>
            </article>
            {/* ///////// */}

            {/* ////ONE CARD /// */}
            <article className={classes.card}>
              <header className={classes.card__thumb}>
                <img src={kumSRI} />
              </header>

              <div className={classes.card__body}>
                {/* /// NAME  */}
                <h2 className={classes.card__title}>
                  <a href="#">Kumkum Bharadwaz</a>
                </h2>
                <h3 className={classes.card__title2}>
                  <a href="#">( Head Co-Ordinator SRIMSH )</a>
                </h3>
                <div className={classes.card__subtitle}>
                  kumkumb2328@gmail.com
                </div>
                <p className={classes.card__description}>
                  Contact : 7991929448
                </p>
              </div>
            </article>
            {/* ///////// */}
          </div>
        </div>

        {/* /////////////////////////////// */}

        {/* ///////////////////////////////ALL CO-ORDINATOR /////////////// */}
        {/* <div className={classes.allTeam}> */}
          {/* //SCE Co-Ordinators */}
          {/* <div className={classes.branchTeamDivMain}>
            <h3>Our Proud Co-ordinators From S.C.E</h3>
            <div className={classes.branchTeam}>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
            </div>
          </div> */}
       
          {/* //BTECH BRANCH Co-Ordinators */}
          {/* <div className={classes.branchTeamDivMain}>
            <h3>Our Proud Co-ordinators From S.C.E</h3>
            <div className={classes.branchTeam}>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
            </div>
          </div> */}
          
          {/* //SIL Co-Ordinators */}
          {/* <div className={classes.branchTeamDivMain}>
            <h3>Our Proud Co-ordinators From S.C.E</h3>
            <div className={classes.branchTeam}>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
            </div>
          </div>
       */}
          {/* //SIP Co-Ordinators */}
          {/* <div className={classes.branchTeamDivMain}>
            <h3>Our Proud Co-ordinators From S.C.E</h3>
            <div className={classes.branchTeam}>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
            </div>
          </div> */}
  
          {/* //SRIMSH Co-Ordinators */}
          {/* <div className={classes.branchTeamDivMain}>
            <h3>Our Proud Co-ordinators From S.C.E</h3>
            <div className={classes.branchTeam}>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
              <h5>Imran Khan</h5>
            </div>
          </div> */}
       
        {/* </div> */}






      </div>
    </>
  );
};

export default Leaderboard;
