import { useState } from "react";
import classes from "./Faq.module.css";

const questions = [
  {
    question: "What is EUREKA 2024?",
    answer:
      "Eureka 2024 is one of the biggest Technical event at Prayagraj.",
  },
  {
    question: "What are the Dates and Timings of event?",
    answer:
      "The event will start at 10:00 AM on 4th July and will be till 6th of July 10:00 PM",
  },
  {
    question: "How can I participate in the event?",
    answer:
      "Explore various events on the EUREKA's website, You can register by Clicking Register button and filling the required form",
  },
  {
    question: "Is there any entry fee for Eureka 2024?",
    answer:
      "No, there is not any entry fee to explore EUREKA 2k24 but to participate in some events you have pay registration fee.",
  },
];

const Faq = () => {
  const [clicked, setClicked] = useState(null);

  const toggle = (i) => {
    if (clicked === i) {
      return setClicked(null);
    }

    setClicked(i);
  };

  return (
    <section className={classes.faqSection}>
      <div className={classes.heading}>FAQ</div>
      <div className={classes.faq}>
        {questions.map((ques, i) => {
          return (
            <div className={classes.single} onClick={() => toggle(i)}>
              <div className={classes.question}>{ques.question}</div>
              <div
                className={`${
                  clicked === i ? classes.answer : classes.noAnswer
                }`}
              >
                {ques.answer}
              </div>
              <span className={classes.btn}>+</span>
            </div>
          );
        })}

       
      </div>
    </section>
  );
};

export default Faq;
