import React, { useState, useRef, useEffect } from "react";
import EventCard from "../common/EventCard/EventCard";
import classes from "./MainEvents.module.css";
import { eventsData } from "../../assets/eventsData";
import ReactGA from "react-ga";

const MainEvents = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  /////////////////////////////////////////////////////////

  const [selectedFilter, setSelectedFilter] = useState("");
  const [filteredItems, setFilteredItems] = useState(eventsData);

  let filters = ["SCE", "SIET", "SIM", "SIP", "SIL", "SRIMSH"];

  const handleFilterButtonClick = (selectedCategory) => {
    setSelectedFilter(selectedCategory);
  };

  useEffect(() => {
    filterItems();
  }, [selectedFilter]);

  const filterItems = () => {
    if (selectedFilter) {
      const filteredItems = eventsData.filter(
        (item) => item.category === selectedFilter
      );
      setFilteredItems(filteredItems);
    } else {
      setFilteredItems([...eventsData]);
    }
  };

  // .......................////////////////////

  return (
    <>
      <div className={classes.events_section}>
        <h1 className={classes.heading}>Events</h1>

        {/* ///////////////////////// */}
        <div className={classes.FilterMainDiv}>
          <div className={classes.buttonDivMain}>
            {filters.map((category, idx) => (
              <button
                className={classes.buttonNew}
                onClick={() => handleFilterButtonClick(category)}
                key={`filters-${idx}`}
                role="button"
              >
                {category}
              </button>
            ))}
          </div>
          {/* <div className={classes.deptHeadDiv}>
            {filters.map((category, idx) => (
              <h2 className={classes.deptHeading}>{category}</h2>
            ))}
          </div> */}
          {/* ///////////////MAPPING ITEMS  */}
          {/* <img src="x" alt="" /> */}
          <div className={classes.events_container}>
            {filteredItems.map((item, idx) => {
              // <h2>SIET</h2>
              return <EventCard eventData={item} key={`eventsData-${idx}`} />;
            })}
          </div>
        </div>
        {/* /////////////////////////// */}
      </div>
    </>
  );
};

export default MainEvents;
