import Faq from "../Faq/Faq";
import classes from "./Contact.module.css";

const Contact = () => {
  return (
    <section id="contact" className={classes.contact}>
      <div className={classes.contactBox}>
        <div className={classes.contentBox}>
          <Faq />
          

          <div className={classes.mapBox}>

            <iframe
              className={classes.map}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.097839687909!2d81.75053387484515!3d25.434992322029164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399acccbb108cfeb%3A0xbd80419a93ea5550!2sShambhunath%20Institute%20of%20Engineering%20and%20Technology!5e0!3m2!1sen!2sin!4v1718957550782!5m2!1sen!2sin"
              // width="600"
              // height="450"
              // style="border:0;"
              // allowfullscreen=""
              title="dypcoemap"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
