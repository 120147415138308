import React from "react";
import imranLogo from "../../assets/Logo.png";
import classes from "./Footer.module.css";

const imraninsta = "https://www.instagram.com/imranpgda/";
const imrantree = "https://linktr.ee/imranpgda";
const imranlinkedin = "https://www.linkedin.com/imranpgda/";
const imranurl = "https://imranmagic.netlify.app/";


const clgwhatsapp = "https://whatsapp.com/channel/0029VaeRoim0gcfGyLkabt2n";
const clginsta = "https://www.instagram.com/usieureka";
const Footer = () => {
  return (
    <div className={classes.footer_outer}>
      <div className={classes.footer}>
        <div className={classes.footer_first}>
          <div className={classes.footer_imran_image_div}>
            <div>
              <img
                className={classes.footer_imran_image}
                src={imranLogo}
                alt="imranLogo"
              ></img>
            </div>
            <div>
              <h1 className={classes.footer_imran}>EUREKA 2024</h1>
            </div>
          </div>
          <p className={classes.footer_imran_description}>
             Presented To You By UTTHAN Shambhunath Institutions
          </p>
          <div className={classes.footer_social_item}>
            <div>
              <a
                href={clginsta}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footer_a_link}
              >
                <img
                  className={classes.footer_social_link}
                  src="https://img.icons8.com/?size=100&id=32292&format=png&color=ffffff"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a
                href={clgwhatsapp}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footer_a_link}
              >
                <img
                  className={classes.footer_social_link}
                  src="https://img.icons8.com/?size=100&id=16712&format=png&color=ffffff"
                  alt=""
                />
              </a>{" "}
            </div>
            
          </div>
        </div>
        
      
        <div className={classes.footer_third}>
          <h2 className={classes.footer_menu_heading}>Contact Us</h2>
          <div className={classes.footer_contact}>
            <div className={classes.footer_contact_item_image}>
              <img
                className={classes.footer_contact_image}
                src="https://img.icons8.com/ios-filled/50/ffffff/marker.png"
                alt=""
              />
            </div>
            <div>
              <p>UTTHAN Shambhunath Institutions,  Jhalwa (Prayagraj) </p>
            </div>
          </div>
          <div className={classes.footer_contact}>
            <div>
              <img
                className={classes.footer_contact_image}
                src="https://img.icons8.com/?size=100&id=1349&format=png&color=ffffff"
                alt=""
              />
            </div>
            <div>
              <p>www.siet.in</p>
            </div>
          </div>
          <div className={classes.footer_contact}>
            <div>
              <img
                className={classes.footer_contact_image}
                src="https://img.icons8.com/glyph-neue/64/ffffff/gmail.png"
                alt=""
              />
            </div>
            <div>
              <p className={classes.mail}>usieureka2024@gmail.com</p>
            </div>
          </div>
        </div>
      </div>



      <div className={classes.footer_creator_main}>
        <div className={classes.footer_creator_div}>
        <h4 className={classes.footer_creator}>
           DEV : Imran Khan (BCA)  {" "} 
        </h4>
        {/* /// Social Contact */}
        <div className={classes.footer_social_item}>
        <h4 className={classes.footer_creator}>@imranpgda &nbsp;&nbsp;</h4>
        <div>
              <a
                href={imraninsta}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footer_a_link}
              >
                <img
                  className={classes.footer_social_link}
                  src="https://img.icons8.com/?size=100&id=SAuKSoGPfNAW&format=png&color=000000"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a
                href={imraninsta}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footer_a_link}
              >
                <img
                  className={classes.footer_social_link}
                  src="https://img.icons8.com/?size=100&id=RhYNENh5cxlS&format=png&color=000000"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a
                href={imranlinkedin}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footer_a_link}
              >
                <img
                  className={classes.footer_social_link}
                  
                  src="https://img.icons8.com/?size=100&id=8808&format=png&color=000000"
                  alt=""
                />
              </a>{" "}
            </div>
            
          </div>
          </div>
      </div>
    </div>
  );
};

export default Footer;
