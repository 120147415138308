import React from "react";
import classes from "./Loading.module.css";


const Loading = () => {
  return (
    <div className={classes.svg_frame}>
      <img id="PreImg" width={200} src="./Logo.png" alt="DEV : Imran Khan" />
     <h2 id="PreTag" >Brought To You By SHAMBHUNATH</h2>
    </div>
  );
};

export default Loading;
