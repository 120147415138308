import React from "react";
import classes from "./ContactUs.module.css";
import Button from "../common/Button/Button";

const ContactUs = () => {

  const handleClick = () => {
    window.open('https://drive.google.com/file/d/1rIOYKaeJuC2-OHaYHaqzr0MPniitM9bX/view?usp=sharing', '_blank'); 
  };

  return (
    <>
      <div className={classes.background}>
        {/* <div className={classes.registerForm}> */}
        <div className={classes.mainDiv}>
          <h3>Nostalgia 2K24</h3>
          <h4>The Alumni Meet</h4>
          {/* //REGISTER Button  */}

         
          <button
            onClick={handleClick}
            className={classes.buttonNew}
            role="button"
          >
            Schedule
          </button>
          {/* //////////////////// */}
        </div>
        {/* <div className={classes.card}>
          <div className={classes.card2}>
              <h1 className={classes.heading}>Contact Us</h1>
              <div className={classes.userData}>
                <div className={classes.input_container}>
                  <label className={classes.input_label}>
                    Name<span className={classes.mandatory}>*</span>
                  </label>
                  <input className={classes.input}/>
                </div>
                <div className={classes.input_container}>
                  <label className={classes.input_label}>
                    Email<span className={classes.mandatory}>*</span>
                  </label>
                  <input className={classes.input}/>
                </div>
                <div className={classes.input_container}>
                  <label className={classes.input_label}>
                    Message<span className={classes.mandatory}>*</span>
                  </label>
                  <textarea className={classes.message}>Enter text Here</textarea>
                </div>
              </div>
              <div className={classes.button}><Button label="Send"/></div>
            </div>

        </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default ContactUs;
