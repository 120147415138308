import React from "react";
import classes from "./Hero.module.css";
import { Link } from "react-router-dom";
import Button from "../common/Button/Button";
import text_img from "./Logo.png";
import ankitP1 from "./PROMO/ankitNBG2.png";
//FOR 2nd PAGE
import kavis from "./PROMO/Kavis.png"

import sunnyP from "./PROMO/SUNNY.png";
import ankitTop from "./PROMO/AnkitBack.png";
import ankitBot from "./PROMO/AnkitBOT.png";
import textP from "./PROMO/TEXT.png";
import textP2 from "./PROMO/TextRed.png";
import topText from "./PROMO/TopText2.png"

const Hero = () => {
  return (
    <section id="home" className={classes.hero}>
      {/* <div class="slider-thumb"></div> */}
      <div className={classes.herobox}>
        {" "}
        {/* MainBox */}
        <img className={classes.rightImg} src={ankitP1} alt="@imranpgda" />
        <div className={classes.headerbox}>
          {" "}
          {/* TextBox */}
          <h1 className={classes.heading}>EUREKA 2024</h1>
          <img src={text_img} className={classes.text_img} alt="text_img" />
          <p className={classes.date}>04 - 06 July 2024</p>
          <Link
            className={classes.anchorBtn}
            rel="noopener noreferrer"
            to="/events"
          >
            <Button
              link="/events"
              label="Explore Our Events"
              className={classes.btn}
            ></Button>
          </Link>
        </div>
      </div>

 {/* ////////////////2nd KAVI SAMMELAN */}


 <div className={classes.Promopage2}>
  <div className={classes.main2Div}>
  <div className={classes.topDiv2 }>
  <img className={classes.topTag} src={topText} alt="Dev @ IMRANPGDA" />
  </div>
  <div className={classes.botDiv2}>
  <img className={classes.kavis} src={kavis} alt="Dev @ IMRANPGDA" />
  <h3 className={classes.botTag}>The Most Awaited Kavi Sammelan First Time AT Prayagraj</h3>
  </div>
      </div>
      </div>

      {/* ////////////3rd Promo Page */}
      <div className={classes.Promopage3}>
        <div className={classes.main3Div}>
        <div className={classes.topDiv}>
          <img
            className={classes.imgTop}
            src={ankitTop}
            alt="developer:@imranpgda "
          />
          <img
            className={classes.imgText}
            src={textP2}
            alt="developer:@imranpgda "
          />
        </div>

        {/* //////////BOTTOM DIV */}
        <div className={classes.botDiv}>
          <img
            className={classes.imgSunny}
            src={sunnyP}
            alt="developer:@imranpgda "
          />
          <div className={classes.midDiv}>
            <h3 className={classes.midText}>Mark Your Calendar for 6th July</h3>
            <img
              className={classes.imgBot}
              src={ankitBot}
              alt="developer:@imranpgda "
            />
          </div>
        </div>
        </div>
      </div>
     
    </section>
  );
};

export default Hero;
