import React, { useEffect } from "react";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
// import LandingPage from "../components/ExtraPages/LandingPage";
import Hero from "../components/Hero/Hero";
// import Gallery  from "../components/Hero/Gallery";
import ReactGA from "react-ga";

const Homepage = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });
  return (
    <>
    {/* <Gallery/> */}
      <Hero />
      {/* <LandingPage/> */}
      <About />
      <Contact />
    </>
  );
};

export default Homepage;
